.softphone {
  margin: 30px;
}
.state {
  margin-bottom: 20px;
}

.ant-select{
  margin-right: 10px;
}
button {
  margin: 0 10px 0;
}
.statusName {
margin: 0 10px 0;
}
.oprate button {
  margin: 0 10px 0;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.dtmf {
  margin-top: 15px;
}
.dtmf-box {
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  margin-top: 5px;
}
.dtmf-box button {
  margin-right: 15px;
  margin-bottom: 15px;
}